import { TCalendarNavigation, TCalendarView } from 'components/scenes/Calendar/DnDCalendar/type';
import { CalendarDateRangeInput } from 'graphql/generated/graphql';
import moment from 'moment-timezone';
import { createContext, FC, useState } from 'react';

const initialDate = moment().weekday(0).hour(0).minute(0).second(0).millisecond(0).toDate();
const initialDateEnd = moment()
  .weekday(0)
  .add(6, 'day')
  .hour(23)
  .minute(59)
  .second(59)
  .millisecond(59)
  .toDate();

const initialNavigationVariables: TCalendarNavigation = {
  date: initialDate,
  view: 'week',
};
const initialTraveledDateRange: CalendarDateRangeInput = {
  start: initialDate,
  end: initialDateEnd,
};

const CalendarNavigationContext = createContext<{
  navigationVariables: TCalendarNavigation;
  traveledDateRange?: CalendarDateRangeInput;
  handleChangeNavigationVariables: (
    newNavigationVariables: Partial<Pick<TCalendarNavigation, 'date' | 'view'>>,
  ) => void;
  resetDateRange: () => void;
}>({
  navigationVariables: initialNavigationVariables,
  handleChangeNavigationVariables: () => {},
  resetDateRange: () => {},
});

export const CalendarNavigationProvider: FC<{ children?: JSX.Element }> = ({ children }) => {
  const [navigationVariables, setNavigationVariables] = useState<TCalendarNavigation>(
    initialNavigationVariables,
  );

  const [traveledDateRange, setTraveledDateRange] = useState<CalendarDateRangeInput | undefined>(
    initialTraveledDateRange,
  );

  const resetDateRange = () => setTraveledDateRange(initialTraveledDateRange);

  const handleChangeNavigationVariables = (
    newNavigationVariables: Partial<TCalendarNavigation> = {},
  ) => {
    const { date: newDate, view: newView } = newNavigationVariables;

    setNavigationVariables((prevNavigationVariables) => {
      const view = newView ?? prevNavigationVariables.view;

      if (newDate || newView) {
        const incomingNextOrPreviousDateMoment = moment(newDate ?? prevNavigationVariables.date)
          .second(0)
          .millisecond(0);
        const incomingNextOrPreviousDateToISOString =
          incomingNextOrPreviousDateMoment.toISOString();

        setTraveledDateRange((prevTraveledDateRange) => {
          // in case of previsou traveled date range is not setted
          if (!prevTraveledDateRange) {
            // check new date if next or previous
            const isNext = incomingNextOrPreviousDateMoment.isAfter(
              moment(prevNavigationVariables.date),
              'd',
            );
            return isNext
              ? { start: prevNavigationVariables.date, end: incomingNextOrPreviousDateToISOString }
              : { start: incomingNextOrPreviousDateToISOString, end: prevNavigationVariables.date };
          }
          let { start, end } = prevTraveledDateRange;
          switch (view) {
            case TCalendarView.Day:
              start = incomingNextOrPreviousDateMoment
                .hour(0)
                .minute(0)
                .second(0)
                .millisecond(0)
                .toDate();
              end = incomingNextOrPreviousDateMoment
                .hour(23)
                .minute(59)
                .second(59)
                .millisecond(59)
                .toDate();
              break;
            case TCalendarView.Month:
              const startDate = moment(incomingNextOrPreviousDateMoment);
              start = startDate
                .date(1)
                .subtract(5, 'd') // car l'affichage n'est pas que sur le mois mais peu commencer avant
                .hour(0)
                .minute(0)
                .second(0)
                .millisecond(0)
                .toDate();
              const endDate = moment(incomingNextOrPreviousDateMoment);
              end = endDate
                .date(31)
                .add(5, 'd') // car l'affichage n'est pas que sur le mois mais peu commencer avant
                .hour(23)
                .minute(59)
                .second(59)
                .millisecond(59)
                .toDate();
              break;
            default:
              start = moment(incomingNextOrPreviousDateMoment)
                .weekday(0)
                .hour(0)
                .minute(0)
                .second(0)
                .millisecond(0)
                .toDate();
              end = moment(incomingNextOrPreviousDateMoment)
                .weekday(0)
                .add(6, 'day')
                .hour(23)
                .minute(59)
                .second(59)
                .millisecond(59)
                .toDate();
          }
          return { start, end };
        });
        return { date: incomingNextOrPreviousDateToISOString, view };
      }

      return { ...prevNavigationVariables, view };
    });
  };

  return (
    <CalendarNavigationContext.Provider
      value={{
        navigationVariables,
        traveledDateRange,
        handleChangeNavigationVariables,
        resetDateRange,
      }}
    >
      {children}
    </CalendarNavigationContext.Provider>
  );
};

export default CalendarNavigationContext;

import React, { createContext, FC, useState } from 'react';
import moment from 'moment-timezone';
import 'moment';
import 'moment/locale/fr';
import {
  // StorageSharedKeyCredential,
  BlobServiceClient,
  // AccountSASPermissions,
  // AccountSASServices,
  // AccountSASResourceTypes,
  // generateAccountSASQueryParameters,
  // SASProtocol,
} from '@azure/storage-blob';
import { azure } from 'config';
import { EN } from '../../constant';

interface IAppContext {
  browserTimezone: string;
  browserLocale: string;
  handleChangeBrowserTimezone?: (timezone: string) => void;
  handleChangeBrowserLocale?: (locale: string) => void;
  blobServiceClient?: BlobServiceClient;
}

interface IAppContextProviderProps {
  children: React.ReactNode;
}

export const AppContext = createContext<IAppContext>({
  browserLocale: EN,
  browserTimezone: moment.tz.guess(),
  blobServiceClient: undefined,
});

export const AppContextProvider: FC<IAppContextProviderProps> = ({ children }) => {
  const [browserTimezone, setBrowserTimezone] = useState<string>(moment.tz.guess());
  const [browserLocale, setBrowserLocale] = useState<string>(EN);
  const accountName = azure.accountName;
  const sasToken = azure.accountKey;

  if (!accountName) throw Error('Azure Storage accountName not found');
  if (!sasToken) throw Error('Azure Storage accountKey not found');

  const blobServiceUri = `https://${accountName}.blob.core.windows.net`;
  // https://YOUR-RESOURCE-NAME.blob.core.windows.net?YOUR-SAS-TOKEN
  const blobServiceClient = new BlobServiceClient(`${blobServiceUri}?${sasToken}`);
  // console.log('info compte SAAS : ', blobServiceClient.getAccountInfo());
  const handleChangeBrowserTimezone = (timezone: string) => {
    setBrowserTimezone(timezone);
  };

  const handleChangeBrowserLocale = (locale: string) => {
    setBrowserLocale(locale);
    moment.locale(locale, { week: { dow: 1 } });
  };

  const contextValue: IAppContext = {
    browserTimezone,
    browserLocale,
    handleChangeBrowserTimezone,
    handleChangeBrowserLocale,
    blobServiceClient,
  };

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

import a3 from './a3';
import admin from './admin';
import authentication from './authentication';
import businessunit from './businessunit';
import calendar from './calendar';
import chat from './chat';
import common from './common';
import dialog from './dialog';
import eod from './eod';
import error from './error';
import exit from './exit';
import issue from './issue';
import leave from './leave';
import map from './map';
import meeting from './meeting';
import onetoone from './onetoone';
import post from './post';
import preference from './preference';
import process from './process';
import project from './project';
import ruche from './ruche';
import social from './social';
import tabs from './tabs';
import team from './team';
import user from './user';
import userPreference from './userPreference';
import coach from './coach';
import database from './database';
import notifications from './notifications';
import coin from './coin';
import badges from './badges';
import expEvents from './expEvents';

const en: Record<string, string> = {
  ...a3,
  ...admin,
  ...authentication,
  ...businessunit,
  ...calendar,
  ...chat,
  ...common,
  ...dialog,
  ...eod,
  ...error,
  ...exit,
  ...issue,
  ...leave,
  ...map,
  ...meeting,
  ...onetoone,
  ...post,
  ...preference,
  ...process,
  ...project,
  ...ruche,
  ...social,
  ...tabs,
  ...team,
  ...user,
  ...userPreference,
  ...coach,
  ...database,
  ...notifications,
  ...coin,
  ...badges,
  ...expEvents,
};
export default en;

export const API_URL = process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:4000/graphql';
export const SUBSCRIPTION_URL =
  process.env.REACT_APP_SUBSCRIPTION_URL || 'ws://localhost:4000/graphql';

export const STREAM_APP_KEY = process.env.REACT_APP_STREAM_APP_KEY;
export const STREAM_APP_ID = process.env.REACT_APP_STREAM_APP_ID;

export const S3_PUBLIC_URL = process.env.REACT_APP_AWS_CDN_ENDPOINT;
export const BLOB_PUBLIC_URL = process.env.REACT_APP_BLOB_CDN_ENDPOINT;

export const OLVY_ORGANIZATION_NAME = process.env.REACT_APP_OLVY_ORGANIZATION_NAME;

export const ENABLE_COACH = process.env.REACT_APP_ENABLE_COACH?.toLowerCase() === 'true';
export const COACH_URL = process.env.REACT_APP_COACH_URL || 'http://127.0.0.1:8000/events';

export const FIREBASE_VAPIDKEY = process.env.REACT_APP_FIREBASE_VAPIDKEY;
export const FIREBASE_APIKEY = process.env.REACT_APP_FIREBASE_APIKEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

export const AZURE_STORAGE_ACCOUNT_NAME = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;
export const AZURE_STORAGE_ACCOUNT_KEY = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_KEY;
export const AZURE_TENANT_ID = process.env.REACT_APP_AZURE_TENANT_ID;
export const AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID;
export const AZURE_CLIENT_SECRET = process.env.REACT_APP_AZURE_CLIENT_SECRET;
export const azure = {
  accountName: AZURE_STORAGE_ACCOUNT_NAME || 'wimtimstagging',
  tenantId: AZURE_TENANT_ID,
  clientId: AZURE_CLIENT_ID,
  clientSecret: AZURE_CLIENT_SECRET,
  accountKey:
    AZURE_STORAGE_ACCOUNT_KEY ||
    'VxKLJTdPwBDb3exoQIuYOSw6/0giPLL5GmMlQUNjiIgDt8G+0QwrxUAmVcwiPrqQdCHcLQeXTecO+AStlP2DuQ==',
};

export const firebaseConfig = {
  apiKey: FIREBASE_APIKEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

const expEvents: Record<string, string> = {
  'expEvent.name.receiveThankYou': 'Remerciement reçu',
  'expEvent.description.receiveThankYou': 'Quand vous recevez un remerciement',
  'expEvent.name.receiveFeedback': 'Retour reçu',
  'expEvent.description.receiveFeedback': 'Quand vous recevez un retour',
  'expEvent.name.sendFeedback': 'Retour envoyé',
  'expEvent.description.sendFeedback': 'Quand vous envoyez un retour',
  'expEvent.name.ideaLowRating': 'Note basse sur une idée',
  'expEvent.description.ideaLowRating': 'Quand votre note est basse (0 < note <= 2)',
  'expEvent.name.ideaMediumRating': 'Note moyenne sur une idée',
  'expEvent.description.ideaMediumRating': 'Quand votre note est moyenne (2 < note <= 4)',
  'expEvent.name.ideaHighRating': 'Note haute sur une idée',
  'expEvent.description.ideaHighRating': 'Quand votre note est haute (note == 5)',
  'expEvent.name.ratedOrCommentedIdeaLowRating':
    "Note ou commentaire d'une idée avec une note faible",
  'expEvent.description.ratedOrCommentedIdeaLowRating':
    "Quand l'idée que vous avez notée ou commentée à une note faible (0 < note <= 2)",
  'expEvent.name.ratedOrCommentedIdeaMediumRating':
    "Note ou commentaire d'une idée avec une note moyenne",
  'expEvent.description.ratedOrCommentedIdeaMediumRating':
    "Quand l'idée que vous avez notée ou commentée à une note moyenne (2 < note <= 4)",
  'expEvent.name.ratedOrCommentedIdeaHighRating':
    "Note ou commentaire d'une idée avec une note haute",
  'expEvent.description.ratedOrCommentedIdeaHighRating':
    "Quand l'idée que vous avez notée ou commentée à une note haute (note == 5)",
  'expEvent.name.issueLowLevel': 'Problème de niveau bas',
  'expEvent.description.issueLowLevel':
    "Lorsqu'un problème de faible niveau est créé pour l'équipe en charge",
  'expEvent.name.issueMediumLevel': 'Problème de niveau moyen',
  'expEvent.description.issueMediumLevel':
    "Lorsqu'un problème de niveau moyen est créé pour l'équipe en charge",
  'expEvent.name.issueHighLevel': 'Problème de niveau haut',
  'expEvent.description.issueHighLevel':
    "Lorsqu'un problème de haut moyen est créé pour l'équipe en charge",
  'expEvent.name.issueLowLevelSolved': 'Problème de bas niveau résolu',
  'expEvent.description.issueLowLevelSolved':
    "Lorsqu'un problème avec un niveau bas est résolu pour l'équipe en charge",
  'expEvent.name.issueMediumLevelSolved': 'Problème de niveau moyen résolu',
  'expEvent.description.issueMediumLevelSolved':
    "Lorsqu'un problème avec un niveau moyen est résolu pour l'équipe en charge",
  'expEvent.name.issueHighLevelSolved': 'Problème de haut niveau résolu',
  'expEvent.description.issueHighLevelSolved':
    "Lorsqu'un problème avec un niveau haut est résolu pour l'équipe en charge",
};
export default expEvents;

import 'moment';
import moment from 'moment-timezone';
import 'moment/locale/fr';
import { createContext, useState } from 'react';

import { EN } from '../../constant';

export interface ISwitchMenuContext {
  locale: string;
  handleChangeLocale?: (locale: string) => void;
}

const LocaleContext = createContext<ISwitchMenuContext>({ locale: EN });

export const LocaleContextProvider = ({ children }: { children: JSX.Element }) => {
  const [locale, setLocale] = useState<string>(EN);

  const handleChangeLocale = (locale: string) => {
    setLocale(locale);
    moment.locale(locale, { week: { dow: 1 } });
  };

  return (
    <LocaleContext.Provider value={{ locale, handleChangeLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleContext;

import { Post_Sort_Type, PostFilterByInput } from 'graphql/generated/graphql';
import { createContext, FC, useState } from 'react';

export type TPostVariables = {
  communityId?: string;
  sortBy?: Post_Sort_Type;
  filterBy?: PostFilterByInput;
  limit?: number;
};

export const PostVariablesContext = createContext<{
  postVariables: TPostVariables;
  handleChangePostVariables: (postVariables: TPostVariables) => void;
}>({ postVariables: {}, handleChangePostVariables: () => {} });

export const PostVariablesContextProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [postVariables, setPostVariables] = useState<TPostVariables>({});

  const handleChangePostVariables = (postVariables: TPostVariables) => {
    setPostVariables((prev) => {
      return { ...prev, ...postVariables };
    });
  };

  return (
    <PostVariablesContext.Provider value={{ postVariables, handleChangePostVariables }}>
      {children}
    </PostVariablesContext.Provider>
  );
};

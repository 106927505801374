// INDIVIDUAL BADGEDS
export enum TIndividualBadgeName {
  // Achievements badges
  GENEROUS = 'badge.name.generous',
  SOLUTION_PROVIDER = 'badge.name.solutionProvider',
  LISTENING_GUY = 'badge.name.listeningGuy',
  ADVISOR = 'badge.name.advisor',

  // Special badges
  FUNNY = 'badge.name.funny',
  LEADER = 'badge.name.leader',
  DISCIPLINED = 'badge.name.disciplined',

  // Points collection badges
  NOVICE = 'badge.name.novice',
  TRAINEE = 'badge.name.trainee',
  BROWN_BELT = 'badge.name.brownBelt',
  PROFICIENT = 'badge.name.proficient',
  GRAND_MASTER = 'badge.name.grandMaster',
  ENLIGHTENED = 'badge.name.enlightened',
}

// COMMUNITY BADGEDS
export enum TCommunityBadgeName {
  // Achievements badges
  PROJECT_KILLER = 'badge.name.projectKiller',
  QUICK = 'badge.name.quick',
  SUPER_SOLVER = 'badge.name.superSolver',
  CONNECTED_GUYS = 'badge.name.connectedGuys',
  SUPER_STAR = 'badge.name.superStar',

  // Special badges
  HOME_SWEET_HOME = 'badge.name.homeSweetHome',
  GROWING_TREE = 'badge.name.growingTree',

  // Points collection badges
  LEARNERS = 'badge.name.learners',
  INTERMEDIATES = 'badge.name.intermediates',
  PERFORMING_TEAM = 'badge.name.performingTeam',
  SOLID_TEAM = 'badge.name.solidTeam',
  DREAM_TEAM = 'badge.name.dreamTeam',
  ULTIMATE_SQUAD = 'badge.name.ultimateSquad',
}

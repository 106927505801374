const ruche: Record<string, string> = {
  'ruche.label.header.message': 'Fournir le bon produit au bon moment au laboratoire',
  'ruche.label.my.coins': 'Mes pièces',

  'ruche.idea.report.message.success': 'Votre rapport a été envoyé avec succès',

  'ruche.idea.label.report': 'Rapport',
  'ruche.idea.label.select.reason': 'Veuillez sélectionner une raison',

  'ruche.idea.reason.inappropriate.content': 'Contenu inapproprié',
  'ruche.idea.reason.harassment.or.intimidation': 'Harcèlement ou intimidation',
  'ruche.idea.reason.undesirable.or.harmful': 'Indésirable ou nocif',
  'ruche.idea.reason.other': 'Autre',

  'ruche.idea.rating.star.details': 'Détails du classement par étoiles',
  'ruche.idea.label.my.rating': 'Mon classement',
  'ruche.idea.label.rating': 'notation',
  'ruche.idea.label.rating.value': '{ratingValue} notation',
  'ruche.problem.label.add.a.problem': 'Ajouter un problème',
  'ruche.problem.label.edit.problem': 'Modifier le probléme',

  'ruche.problem.placeholder.title': 'Donnez un titre à votre problème',
  'ruche.problem.placeholder.content': 'Décrivez votre problème',

  'ruche.feedback.content': 'Contenu des retours',
  'ruche.feedback.description':
    "Quel est votre retour ? Rappelez-vous les deux clés d'un bon retour : une intention positive avec des conseils pour l'avenir.",
  'ruche.feedback.content.reply': 'Tapez quelque chose pour répondre',
  'ruche.send.feedback': 'Envoyer un retour',
  'ruche.send.feedback.success': 'Envoi de retour réussi',
  'ruche.reply.feedback.success': 'Réponse réussie',
  'ruche.send.feedback.to': 'Envoyer un retour à {name}',
  'ruche.received.feedback': 'Vous avez reçu un retour',

  'ruche.thanks.content': 'Contenu de remerciement',
  'ruche.thanks.score.number.received': 'Nombre de points reçus',
  'ruche.send.thanks': 'Envoyer un remerciement',
  'ruche.received.thanks': 'Vous avez reçu un remerciement',
  'ruche.received.thank.you': 'Merci',
  'ruche.send.thanks.success': 'Remerciement envoyé avec succès',
  'ruche.send.thanks.error': "Erreur lors de l'envoie du remerciement !",
  'ruche.send.thanks.to': 'Envoyer un remerciement à {name}',
  'ruche.chat.with': 'Discuter avec {name}',
  'ruche.thanks.points': 'Points',
  'ruche.thanks.not.have.point': "Vous avez déjà gagné des points aujourd'hui",
  'ruche.notification.no.item.yet': 'Pas encore de notification',
  'ruche.search.no.result': 'Pas de résultat',
  'ruche.thanks.response.field': 'Champ de réponse',
  'ruche.response.thank.success': 'Réponse au remerciement envoyée avec succès',
  'ruche.response.thank.you': 'Réponse du remerciement',
  'ruche.response.thank.description': '{name} a envoyé une réponse a votre remerciement',
  'ruche.response.feedback.success': 'Envoie du retour avec succès',
  'ruche.response.feedback.title': 'Réponse a votre retours !',
  'ruche.response.feedback.description': '{name} a envoyé une réponse a votre retour',

  'ruche.rating.period.end.indicator':
    "La période d'évaluation de cette idée se termine le: {date}",

  //tooltip title
  'ruche.tooltip.title.coworker.list':
    'Grâce à cette liste, vous pouvez discuter avec un collègue, lui envoyer un remerciement ou un feedback',
  'ruche.tooltip.title.chat': 'Discuter avec des collègues',
  'ruche.tooltip.title.notification': 'Notification',
  'ruche.tooltip.erreur.chat': 'Erreur de chargement du chat',
  'ruche.label.switch-beetween-indicator':
    'Basculez entre vos équipes, votre entreprise et votre unité commerciale',
  'ruche.label.switch-beetween-company': 'Basculez entre vos entreprises',

  //Ruche tabs tooltip title
  'ruche.tabs.tooltip.title.idea':
    'Ici, vous pouvez partager des idées de manière anonyme avec vos collègues',
  'ruche.tabs.tooltip.title.issue':
    "Ici, vous pouvez partager les problèmes que vous avez rencontrés avec vos collègues. Il est important de détailler en profondeur la problématique afin de mieux l'analyser",
  'ruche.tabs.tooltip.title.what.is.up':
    'Ici, vous pouvez partager des nouvelles professionnelles et personnelles avec vos collègues',
  'ruche.tabs.tooltip.title.advice':
    'Ici, vous pouvez partager vos précieux conseils avec vos collègues',
  'ruche.tabs.tooltip.title.have.fun':
    'Ici, vous pouvez partager vos joies et vos peines (have fun) avec vos collègues',
  'ruche.tabs.tooltip.title.process':
    "Ici, vous pouvez partager un processus avec vos collègues. Ils peuvent ensuite l'utiliser comme ligne directrice pour faire avancer les choses",
  'ruche.tabs.tooltip.title.question':
    'Ici, vous pouvez poser ou rechercher une question à vos collègues. Vous pouvez également écrire une réponse en cliquant sur une question',
  'ruche.call.with': 'Appelez {name}',
  'ruche.call.created.successfuly': 'Appel crée avec succès',
};
export default ruche;

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

import useCompanyLayoutStyles from '../CompanyLayoutView/styles';
import useHeaderStyles from '../Header/styles';

const CompanyLayoutSkeleton = () => {
  const companyLayoutClasses = useCompanyLayoutStyles();
  const headerClasses = useHeaderStyles();

  return (
    <Grid container wrap="nowrap" className={companyLayoutClasses.container}>
      <Paper
        style={{ display: 'flex', flexDirection: 'column', padding: 16, boxSizing: 'border-box' }}
        classes={{ root: companyLayoutClasses.paper }}
      >
        <Skeleton variant="rectangular" width={216} height={170} style={{ borderRadius: 20 }} />

        {Array.from({ length: 5 }).map((_, i) => (
          <Skeleton key={i} height={54} style={{ marginTop: 20 }} />
        ))}
      </Paper>

      <Grid
        container
        item
        xs
        flexDirection="column"
        wrap="nowrap"
        className={companyLayoutClasses.main}
      >
        <Grid item flex="0 0 min-content">
          <Grid container wrap="nowrap" columnGap={2} classes={{ root: headerClasses.root }}>
            <Skeleton variant="text" style={{ flex: 1 }} />

            <Grid
              item
              xs="auto"
              container
              wrap="nowrap"
              columnGap={2}
              className={headerClasses.rightSection}
            >
              <Skeleton
                width={200}
                height={49}
                style={{ borderRadius: 25, transform: 'scale(1, 1)' }}
              />
              <Skeleton
                width={130}
                height={49}
                style={{ borderRadius: 34, transform: 'scale(1, 1)' }}
              />
              <Skeleton
                width={167}
                height={49}
                style={{ borderRadius: 34, transform: 'scale(1, 1)' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompanyLayoutSkeleton;

import React from 'react';

export type TIds = {
  userId: string;
  communityId: string;
  companyMemberId: string;
};

export type TMaybeIds = {
  userId?: string;
  communityId?: string;
  companyMemberId?: string;
};

export const IdsContext = React.createContext<{
  ids: TIds;
  handleChangeIds: (ids?: TMaybeIds) => void;
}>({
  ids: { userId: '', communityId: '', companyMemberId: '' },
  handleChangeIds: () => {},
});

export const IdsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [ids, setIds] = React.useState<TIds>({
    userId: '',
    communityId: '',
    companyMemberId: '',
  });

  const handleChangeIds = (ids?: TMaybeIds) => {
    setIds((prev) => {
      if (ids) {
        return { ...prev, ...ids };
      }
      return prev;
    });
  };

  return <IdsContext.Provider value={{ ids, handleChangeIds }}>{children}</IdsContext.Provider>;
};

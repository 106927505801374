import { CommunitiesFilter, Maybe } from 'graphql/generated/graphql';
import React, { createContext, FC, useMemo } from 'react';

export type TCalendarContextVariables = {
  communityKeyParam: string;
  communityIds?: string[];
  communitiesFilter?: CommunitiesFilter;
};

export type TCalendarContext = {
  variables: TCalendarContextVariables;
  handleChangeVariables: (variables?: TCalendarContextVariables) => void;
};

export const CalendarContext = createContext<TCalendarContext>({
  variables: {
    communityKeyParam: CommunitiesFilter.All.toLocaleLowerCase(),
    communityIds: undefined,
    communitiesFilter: undefined,
  },
  handleChangeVariables: () => {},
});

export interface ICalendarProviderProps {
  initialCommunityKeyParam?: Maybe<string>;
  children: React.ReactNode;
}

export const CalendarProvider: FC<ICalendarProviderProps> = (props) => {
  const { initialCommunityKeyParam, children } = props;

  const communityKeyParam = useMemo(
    () => initialCommunityKeyParam ?? CommunitiesFilter.All.toLocaleLowerCase(),
    [initialCommunityKeyParam],
  );

  const initialCommunitiesFilter = useMemo(() => {
    switch (communityKeyParam) {
      case CommunitiesFilter.Mine.toLocaleLowerCase():
        return CommunitiesFilter.Mine;
      case CommunitiesFilter.Others.toLocaleLowerCase():
        return CommunitiesFilter.Others;
      case CommunitiesFilter.All.toLocaleLowerCase():
        return CommunitiesFilter.All;
      default:
        return undefined;
    }
  }, [communityKeyParam]);

  const initialCommunityIds = useMemo(
    () => (!initialCommunitiesFilter ? [communityKeyParam] : undefined),
    [communityKeyParam, initialCommunitiesFilter],
  );

  const [variables, setVariables] = React.useState<TCalendarContextVariables>({
    communityKeyParam,
    communityIds: initialCommunityIds,
    communitiesFilter: initialCommunitiesFilter,
  });

  const handleChangeVariables = (variables?: TCalendarContextVariables) => {
    if (variables) setVariables((prev) => ({ ...prev, ...variables }));
  };

  return (
    <CalendarContext.Provider value={{ variables, handleChangeVariables }}>
      {children}
    </CalendarContext.Provider>
  );
};

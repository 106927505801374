import { SITE_MAP } from 'constant';
import { MainRole, useCheckAdminQuery } from 'graphql/generated/graphql';
import { Navigate, useLocation } from 'react-router-dom';
import GeneralLoading from '../GeneralLoading';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { data, loading } = useCheckAdminQuery();

  const navigateTo = `${SITE_MAP.Client.path}/${data?.admin.slug}`;

  if (loading) return <GeneralLoading />;

  if (data?.admin.userAccess !== MainRole.Admin) {
    return <Navigate to={navigateTo} state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;

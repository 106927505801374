import React, { createContext, FC, useEffect, useMemo, useState } from 'react';

import {
  CompanyMember,
  CoworkersMemberQueryHookResult,
  useCoworkersMemberQuery,
} from '../../graphql/generated/graphql';
export interface IHierarchyContext {
  selectedCoworker?: CompanyMember;
  setSelectedCoworker?: React.Dispatch<React.SetStateAction<CompanyMember | undefined>>;
  coworkers?: CompanyMember[];
  loading?: boolean;
  refetch?: CoworkersMemberQueryHookResult['refetch'];
}

export const HierarchyContext = createContext<IHierarchyContext>({});

export interface IHierarchyProviderProps {
  children: React.ReactNode;
}

export const HierarchyProvider: FC<IHierarchyProviderProps> = React.memo((props) => {
  const { children } = props;

  const { data, loading, refetch } = useCoworkersMemberQuery({
    variables: {
      withoutMe: false,
    },
  });
  const [selectedCoworker, setSelectedCoworker] = useState<CompanyMember | undefined>();

  const [contextValue, setcontextValue] = useState<IHierarchyContext>({});

  useEffect(() => {
    setcontextValue({
      coworkers: data?.coworkersMember as CompanyMember[],
      loading,
      selectedCoworker,
      refetch,
      setSelectedCoworker,
    });
  }, [data, loading, refetch, selectedCoworker]);

  const value = useMemo(() => ({ contextValue }), [contextValue]);

  return (
    <HierarchyContext.Provider value={value.contextValue}>{children}</HierarchyContext.Provider>
  );
});

export default HierarchyContext;

import { useApolloClient, useQuery } from '@apollo/client';

import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import { GetSnackbarStateQuery } from 'graphql/generated/graphql';
import { GET_SNACKBAR_STATE } from 'graphql/localState/snackbar/query';

const CustomSnackBar = () => {
  const client = useApolloClient();
  const { data } = useQuery<GetSnackbarStateQuery>(GET_SNACKBAR_STATE, {
    fetchPolicy: 'cache-only',
  });

  const closeSnack = () => {
    client.writeQuery({
      query: GET_SNACKBAR_STATE,
      data: {
        ...data,
        snackBar: {
          ...data?.snackBar,
          isOpen: false,
          __typename: 'SnackBar',
        },
      },
    });
  };

  if (data?.snackBar) {
    const { isOpen, message, type } = data.snackBar;
    return (
      <Snackbar
        id="snackbar"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!isOpen}
        onClose={closeSnack}
        autoHideDuration={6000}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={closeSnack}
          severity={(type as AlertColor) ?? 'success'}
        >
          <Typography>{message}</Typography>
        </Alert>
      </Snackbar>
    );
  }

  return null;
};

export default CustomSnackBar;

const expEvents: Record<string, string> = {
  'expEvent.name.receiveThankYou': 'Receive thank you',
  'expEvent.description.receiveThankYou': 'When you receive a thank you',
  'expEvent.name.receiveFeedback': 'Receive feedback',
  'expEvent.description.receiveFeedback': 'When you receive a feedback',
  'expEvent.name.sendFeedback': 'Send feedback',
  'expEvent.description.sendFeedback': 'When you send a feedback',
  'expEvent.name.ideaLowRating': 'Idea low rating',
  'expEvent.description.ideaLowRating': 'When your idea rating is low (0 < rating <= 2)',
  'expEvent.name.ideaMediumRating': 'Idea medium rating',
  'expEvent.description.ideaMediumRating': 'When your idea rating is medium (2 < rating <= 4)',
  'expEvent.name.ideaHighRating': 'Idea high rating',
  'expEvent.description.ideaHighRating': 'When your idea rating is high (rating == 5)',
  'expEvent.name.ratedOrCommentedIdeaLowRating': 'Rated or commented idea low rating',
  'expEvent.description.ratedOrCommentedIdeaLowRating':
    'When the idea you have rated or commented have low rating (0 < rating <= 2)',
  'expEvent.name.ratedOrCommentedIdeaMediumRating': 'Rated or commented idea medium rating',
  'expEvent.description.ratedOrCommentedIdeaMediumRating':
    'When the idea you have rated or commented have medium rating (2 < rating <= 4)',
  'expEvent.name.ratedOrCommentedIdeaHighRating': 'Rated or commented idea high rating',
  'expEvent.description.ratedOrCommentedIdeaHighRating':
    'When the idea you have rated or commented have high rating (rating == 5)',
  'expEvent.name.issueLowLevel': 'Issue low level',
  'expEvent.description.issueLowLevel':
    'When issue with a low level is created and the community is the team in charge',
  'expEvent.name.issueMediumLevel': 'Issue medium level',
  'expEvent.description.issueMediumLevel':
    'When issue with a medium level is created and the community is the team in charge',
  'expEvent.name.issueHighLevel': 'Issue high level',
  'expEvent.description.issueHighLevel':
    'When issue with a high level is created and the community is the team in charge',
  'expEvent.name.issueLowLevelSolved': 'Issue low level solved',
  'expEvent.description.issueLowLevelSolved':
    'When issue with a low level is solved and the community is the team in charge',
  'expEvent.name.issueMediumLevelSolved': 'Issue medium level solved',
  'expEvent.description.issueMediumLevelSolved':
    'When issue with a medium level is solved and the community is the team in charge',
  'expEvent.name.issueHighLevelSolved': 'Issue high level solved',
  'expEvent.description.issueHighLevelSolved':
    'When issue with a high level is solved and the community is the team in charge',
};
export default expEvents;

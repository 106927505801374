import { createContext, FC, useEffect, useMemo, useState } from 'react';

import {
  GetMyPreferencesQueryResult,
  useGetMyPreferencesQuery,
  UserPreference,
} from 'graphql/generated/graphql';

export interface IUserPreferenceContext {
  userPreferences?: UserPreference;
  loading?: boolean;
  refetch?: GetMyPreferencesQueryResult['refetch'];
}
export interface IUserPreferenceProvider {
  children?: React.ReactNode;
}
export const UserPreferenceContext = createContext<IUserPreferenceContext>({});

// TODO: avoid useEffect here
const UserPreferenceProvider: FC<IUserPreferenceProvider> = ({ children }) => {
  const [contextValue, setContextValue] = useState<IUserPreferenceContext>({});

  const { data, loading, refetch } = useGetMyPreferencesQuery();

  useEffect(() => {
    setContextValue({
      loading,
      userPreferences: data?.userPreference as UserPreference,
      refetch,
    });
  }, [data, loading, refetch]);

  const value = useMemo(() => ({ contextValue }), [contextValue]);
  return (
    <UserPreferenceContext.Provider value={value.contextValue}>
      {children}
    </UserPreferenceContext.Provider>
  );
};

export default UserPreferenceProvider;

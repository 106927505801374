import { useDisplaySnackBar } from 'components/scenes/hooks/useDisplaySnackBar';
import {
  CurrentCompanyProcessQueryResult,
  Post,
  Post_Sort_Type,
  useCurrentCompanyProcessQuery,
} from 'graphql/generated/graphql';
import React, { createContext, FC, useEffect, useMemo, useState } from 'react';

export interface IProcessContextProps {
  filterValue?: string | undefined;
  setFilterValue?: React.Dispatch<React.SetStateAction<string | undefined>>;
  processes?: Post[];
  loadingProcesses?: boolean;
  refetch?: CurrentCompanyProcessQueryResult['refetch'];
}

const ProcessContext = createContext<IProcessContextProps>({});

export const ProcessProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  /********************** Filter by query string *****************/
  const [filterValue, setFilterValue] = React.useState<string>();
  const displaySnackBar = useDisplaySnackBar();
  const {
    data: processData,
    loading: loadingProcesses,
    refetch,
  } = useCurrentCompanyProcessQuery({
    variables: { sortBy: Post_Sort_Type.Newest, filterString: filterValue ? filterValue : null },
    onError: (err) => {
      displaySnackBar('error.failed.to.fetch.company.process', err.message, 'error');
    },
  });
  const [value, setContextValue] = useState<IProcessContextProps>({});

  useEffect(() => {
    setContextValue({
      processes: processData?.currentCompanyProcess as Post[],
      loadingProcesses,
      filterValue,
      refetch,
      setFilterValue,
    });
  }, [filterValue, loadingProcesses, processData]);

  const context = useMemo(() => ({ value }), [value]);

  return <ProcessContext.Provider value={context.value}>{children}</ProcessContext.Provider>;
};

export default ProcessContext;

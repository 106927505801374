const badges: Record<string, string> = {
  'badge.name.projectKiller': 'Project Killer',
  'badge.description.projectKiller': 'Finish 100 projects as a team.',
  'badge.name.quick': 'Quick',
  'badge.description.quick': 'Chek 100 actions before the due date.',
  'badge.name.superSolver': 'Super Solver',
  'badge.description.superSolver': 'Solve more than 100 issues.',
  'badge.name.ConnectedGuys': 'The members of the team received more than 100 feedback in total.',
  'badge.description.ConnectedGuys': 'Connected Guys',
  'badge.name.SuperStar': 'Super Star',
  'badge.description.SuperStar': 'Reach the top of the team Ranking.',

  // Special badges
  'badge.name.homrSweetHome': 'Home Sweet Home',
  'badge.description.homrSweetHome': 'Have more than 10 members in the team.',
  'badge.name.growingTree': 'Growing tree',
  'badge.description.growingTree': 'Have an indicator on  progress for 5 consecutive days.',

  // Points collection badges
  'badge.name.learners': 'Learners',
  'badge.description.learners': '0 - 1000 experiences',
  'badge.name.intermediates': 'Intermediates',
  'badge.description.intermediates': '1000 - 5000 experiences',
  'badge.name.performingTeam': 'Performing team',
  'badge.description.performingTeam': '5000 - 15000 experiences',
  'badge.name.solidTeam': 'Solid team',
  'badge.description.solidTeam': '15000 - 40000 experiences',
  'badge.name.dreamTeam': 'Dream team',
  'badge.description.dreamTeam': '40000 - 100000 experiences',
  'badge.name.ultimateSquad': 'Ultimate Squad',
  'badge.description.ultimateSquad': '100000 - 200000 experiences',

  'badge.name.generous': 'Generous',
  'badge.description.generous': 'Send 100 thanks to more than 3 different people.',
  'badge.name.solutionProvider': 'Solution Provider',
  'badge.description.solutionProvider':
    'Be responsible for more than  100 issues with "Solved status".',
  'badge.name.listeningGuy': 'Listening guy',
  'badge.description.listeningGuy': 'Recieve more than 100 feedback from other people.',
  'badge.name.advisor': 'Advisor',
  'badge.description.advisor': 'Send more than 100 pieces of advice from the hive.',

  // Special badges
  'badge.name.funny': 'Funny',
  'badge.description.funny': 'You will collect this badge when you get recognition from the herd.',
  'badge.name.leader': 'Leader',
  'badge.description.leader': 'You will collect this badge when you get recognition from the herd.',
  'badge.name.disciplined': 'Disciplined',
  'badge.description.disciplined': 'Be consistent on your, usage of Orizon.',

  // Points collection badges
  'badge.name.novice': 'Novice',
  'badge.description.novice': '0 - 1000 experiences',
  'badge.name.trainee': 'Trainee',
  'badge.description.trainee': '1000 - 5000 experiences',
  'badge.name.brownBelt': 'Brown belt',
  'badge.description.brownBelt': '5000 - 15000 experiences',
  'badge.name.proficient': 'Proficient',
  'badge.description.proficient': '15000 - 40000 experiences',
  'badge.name.grandMaster': 'Grand Master',
  'badge.description.grandMaster': '40000 - 100000 experiences',
  'badge.name.enlightened': 'Enlightened',
  'badge.description.enlightened': '100000 - 200000 experiences',
};
export default badges;

const badges: Record<string, string> = {
  'badge.name.projectKiller': 'Tueur de projet',
  'badge.description.projectKiller': 'Terminer 100 projets dans une équipe.',
  'badge.name.quick': 'Rapide Comme le Vent',
  'badge.description.quick': 'Terminez 100 actions avant la date de fin.',
  'badge.name.superSolver': 'Centurion des Solutions',
  'badge.description.superSolver': 'Résous plus de 100 problèmes.',
  'badge.name.connectedGuys': 'Ambassadeur des Retours',
  'badge.description.connectedGuys': "Les membres de l'équipe ont reçu plus de 100 retours.",
  'badge.name.superStar': 'Super Star',
  'badge.description.superStar': 'Atteignez le sommet du classement par équipe.',

  // Special badges
  'badge.name.homeSweetHome': 'Bienvenu chez vous',
  'badge.description.homeSweetHome': "Avoir plus de 10 membres dans l'équipe.",
  'badge.name.growingTree': 'Arbre en croissance',
  'badge.description.growingTree':
    'Avoir un indicateur en progression pendant 5 jours consécutifs.',

  // Points collection badges
  'badge.name.learners': 'Apprenants',
  'badge.description.learners': '0 - 1000 expériences',
  'badge.name.intermediates': 'Intermédiaires',
  'badge.description.intermediates': '1000 - 5000 expériences',
  'badge.name.performingTeam': 'Équipe performante',
  'badge.description.performingTeam': '5000 - 15000 expériences',
  'badge.name.solidTeam': 'Équipe solide',
  'badge.description.solidTeam': '15000 - 40000 expériences',
  'badge.name.dreamTeam': 'Équipe de rêve',
  'badge.description.dreamTeam': '40000 - 100000 expériences',
  'badge.name.ultimateSquad': 'Escouade ultime',
  'badge.description.ultimateSquad': '100000 - 200000 expériences',

  'badge.name.generous': 'Genereux',
  'badge.description.generous': 'Envoyez 100 remerciements a plus de 3 personnes différentes.',
  'badge.name.solutionProvider': 'Fournisseur de solution',
  'badge.description.solutionProvider':
    'Soyez le résponsable de plus de 100 problèmes dans le status résolu.',
  'badge.name.listeningGuy': "Une personne à l'écoute",
  'badge.description.listeningGuy': "Recevez plus de 100 retours d'une autre personne.",
  'badge.name.advisor': 'Conseiller',
  'badge.description.advisor': 'Envoyez plus de 100 conseils dans la ruche.',

  // Special badges
  'badge.name.funny': 'Drôle',
  'badge.description.funny': 'Arrive bientot. Preparez-vous ...',
  'badge.name.leader': 'Chef',
  'badge.description.leader': 'Arrive bientot. Preparez-vous ...',
  'badge.name.disciplined': 'Discipliné',
  'badge.description.disciplined': 'Arrive bientot. Preparez-vous ...',

  // Points collection badges
  'badge.name.novice': 'Novice',
  'badge.description.novice': '0 - 1000 expériences',
  'badge.name.trainee': 'Stagiaire',
  'badge.description.trainee': '1000 - 5000 expériences',
  'badge.name.brownBelt': 'Ceinture marron',
  'badge.description.brownBelt': '5000 - 15000 expériences',
  'badge.name.proficient': 'Compétent',
  'badge.description.proficient': '15000 - 40000 expériences',
  'badge.name.grandMaster': 'Grand Maître',
  'badge.description.grandMaster': '40000 - 100000 expériences',
  'badge.name.enlightened': 'Éclairé',
  'badge.description.enlightened': '100000 - 200000 expériences',
};
export default badges;

import { StyledEngineProvider } from '@mui/material/styles';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging/sw';
import React from 'react';
import { createRoot, Root } from 'react-dom/client';

import App from './App';
import { ApolloClientContextProvider } from './contexts/ApolloClientContext/ApolloClientContext.tsx';
import { AppContextProvider } from './contexts/AppContext';

import { firebaseConfig } from './config.ts';
import './index.css';
import reportWebVitals from './reportWebVitals';

// set firebase
const firebaseApp = initializeApp(firebaseConfig);
getMessaging(firebaseApp);

const container = document.getElementById('root');
let root: Root;
if (container) {
  root = createRoot(container); // createRoot(container!) si vous utilisez TypeScript

  root.render(
    <React.StrictMode>
      <ApolloClientContextProvider>
        <StyledEngineProvider injectFirst>
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </StyledEngineProvider>
      </ApolloClientContextProvider>
    </React.StrictMode>,
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

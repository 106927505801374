import { S3_PUBLIC_URL, BLOB_PUBLIC_URL } from 'config';

export const generatePublicUrl = (key?: string) => (key ? `${S3_PUBLIC_URL}/${key}` : undefined);
export const generateBlobPublicUrl = (key?: string) =>
  key ? `${BLOB_PUBLIC_URL}/${key}` : undefined;

export const getAwsPublicUrl = (key: string) => {
  const fileType = key.split('.').pop() ?? 'jpg';

  const image_webp = ['jpg', 'png', 'jpeg'].includes(fileType.toLowerCase()) ? '?format=webp' : '';

  return `${S3_PUBLIC_URL}/${key}${image_webp}`;
};
export const getBlobPublicUrl = (key: string) => {
  const fileType = key.split('.').pop() ?? 'jpg';

  const image_webp = ['jpg', 'png', 'jpeg'].includes(fileType.toLowerCase()) ? '?format=webp' : '';

  return `${BLOB_PUBLIC_URL}/${key}${image_webp}`;
};
